@import "src/javascript/scss/default/utils/variables";

.person-card-picture-and-type{
  display: flex;
  justify-content: center;
  align-items: center;
}

.person-card-picture-and-type__type{
  width: calc(100% - 40px);
  height: 25px;
  border-radius: 30px;
  position: absolute;
}

.person-card-picture-and-type__picture{
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border: 1px solid $color_blackish;
  object-fit: cover;
}