@import '../../../scss/default/utils/variables';

.connection-indicators {
  display: flex;

  .icon {
    width: fit-content;
  }
}

.connection-indicators__gateway,
.connection-indicators__signal {
  width: 60px;
  display: flex;
  justify-content: center;
  text-align: center;

  &--connectionLost .icon svg {
    color: $color_connection_lost;
  }
}