@import "../../scss/default/utils/variables";

.locations__grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 0.06fr 1fr;
}

.locations__button {
    width: 65px;
    height: 35px;
    padding: 8px;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
}

.locations__button div {
    margin: auto 0;
}

.locations__button--activated {
    background-color: $color_background;
    color: $color_top_navigation;
}

.locations__button--inactivated {
    color: $color_white;
    cursor: default;
}

.locations__button--inactivated svg {
    color: $color_white;
}

.locations__vessels-whereabouts {
    min-width: 150px;
    height: fit-content;
    background-color: $color_top_navigation;
    padding: 6px 10px 6px 6px;
    text-align: center;
}

.locations__whereabouts-container {
    display: flex;
    gap: 15px;

    margin-top: 5px;
    margin-bottom: 5px;

    justify-content: flex-start;
    align-items: center;

    & > *:last-child {
        margin-left: auto;
        margin-right: 0;
    }
}

.locations__whereabouts {
    display: flex;
    z-index: 1;
}

.locations-person-whereabouts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 16px;
}

.locations__title {
    color: $color_white;
    font-size: 1.2rem;
}

.locations__holder {
    width: 12%;
    min-width: $person-card-width;
}

.locations__icon {
    display: flex;
    min-height: 30px;
    width: 50px;

    align-items: center;
    justify-content: center;
}

.locations__icon svg {
    color: $color_white;
    width: 30%;
}

.locations__icon--visible svg {
    transform: rotateX(180deg);
}

.locations__grid .person-group {
    padding-top: 0;
    padding-bottom: 0;

    margin-left: 0;
    margin-right: 0;
}

.locations__grid .person-group__content {
    padding-top: 15px;
    padding-bottom: 15px;
}