@import '../../scss/default/mixins';

.person-transfer-state {
  display: flex;
  flex-direction: column;
}

.disembark-all {
  @include action-button();

  &--loading {
    pointer-events: unset;
    opacity: 50%;
  }
}
