@import "src/javascript/scss/default/utils/variables";

.person-card-detail__content{
  width: 450px;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 580px;
  z-index: 11;
}

.person-card-detail__overlay{
  position: fixed;
  cursor: pointer;
  background-color: $color_dark_background_overlay;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}