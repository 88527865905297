@import "../../../scss/default/utils/variables";

.page-selection {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  gap: 30px;
}

.page-selection-element {
  justify-content: center;
  display: flex;
}

.page-selection-element__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // The selected menu item has a different background and this goes to the top
  // We have to put the padding-top on all the children except the container holding
  // the menu items. There we put the padding-top on the item itself.
  margin-top: -10px;
  margin-bottom: -10px;

  padding: 10px 10px 0 10px;
  width: 80px;

  color: var(--raf_color_grey_light);

  // The links color blue on hover
  &:hover {
    color: var(--raf_color_grey_light);
  }

  &--active:hover {
    color: $color_top_navigation;
  }

  // Showing the border on hover just looks weird
  &--active,
  &:active,
  &:focus {
    color: $color_top_navigation;
    background-color: $color_navigation_active_background;

    .page-selection-element__label::after {
      display: block;
      content: '';
      width: 100%;
      height: 5px;
      background-color: $color_navigation_active_border;
      border-radius: 5px;
    }
  }
}

.page-selection-element__label {
  display: inline-block;
  height: 100%;
}

.page-selection-element .icon {
  margin: 0;
  width: auto;
  opacity: 1;
}

// The SVG of the "persons" page doesn't fill to the top in order to preserve it's ratio
// Using a fixed "height" in combination with a "min-width" fixes this.
.page-selection-element a svg,
.page-selection-element a .svg-inline--fa {
  min-width: 25px;
  height: 25px;
}
