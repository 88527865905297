@import "src/javascript/scss/default/utils/variables";

.tracking-circles{
  transform: rotate(-90deg);
}

.tracking-circle{
  width: 100%;
  height: 100%;
  fill: none;
  stroke-linecap: round;
}
.tracking-circle__outer{
  stroke: $color_grey_light;
}

.tracking-circle__inner{
  stroke: $color_blackish;
}