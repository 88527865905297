.loading-as-overlay-wrapper{
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
}

.loading-as-overlay-message{
  margin-top: 5px;
  text-align: center;
  padding: 0 50px;
}

.loading-page-svg {
  width: 70px;
  height: 70px;
}
