@import '../../../scss/default/utils/variables';

.person-group {
  padding-top: 15px;
  padding-bottom: 15px;

  margin-left: 20px;
  margin-right: 20px;
}

.person-group__title {
  display: grid;
  grid-template-columns: 1fr minmax($action_button_height, 6 * $action_button_height);
  grid-gap: 15px;

  border-bottom: 2px solid $color_light_grey;
}


.person-group__content {
  padding: 8px;
  margin: 0 auto;

  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(5, auto);
  justify-content: flex-start;
}