@import "src/javascript/scss/default/utils/variables";
@import "src/javascript/scss/default/mixins";

.person-card-detail-body{
  background-color: $white;

}

.person-card-detail-body__top{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  @include boarding-state();
}

.person-card-detail-body__list{
  padding: 20px;
  min-height: 250px;
}

.person-card-detail-body__list>:not(:first-child){
  margin-top: 20px;
}

.person-card-detail-body__list-item{
  display: flex
}

.person-card-detail-body__list-header{
  display: flex;
  padding-bottom: 3px;
  margin-bottom: 3px;
  border-bottom: 1px solid $color_light_grey;
}

.person-card-detail-body__icon{
  color: $color_grey;
  margin-right: 15px;
}

.person-card-detail-body__teams{
  display: flex;
}

.person-card-detail-body__team{
  border: 2px solid $color_planned_boarded;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 50px;
  padding: 5px;
  color: $color_planned_boarded;
  font-weight: 700;
  margin-right: 10px;
}