@import "./utils/variables";

@mixin boarding-state(){
  &--planned-boarded{
    background-color: $color_planned_boarded;
    color: $white;
  }
  &--unplanned-boarded{
    background-color: $color_unplanned_boarded;
    color: $white;
  }
  &--planned-not-boarded{
    background-color: $color_planned_not_boarded;
    color: $color_grey_dark;
  }
  &--unplanned-not-boarded{
    background-color: $color-unplanned_not_boarded;
    color: $color_grey_dark;
  }
}

@mixin person-type(){
  &--VISITOR{
    background-color: $color_visitor;
  }
  &--VEHICLE_CREW{
    background-color: $color_crew;
  }
  &--OFFSHORE_WORKER{
    background-color: $color_worker;
  }
  &--UNDEFINED{
    background-color: $color_grey;
  }
}

@mixin action-button() {
  cursor: pointer;

  background-color: $color_light_grey;
  border-color: transparent;
  color: $color_black;

  display: flex;
  align-items: center;
  justify-content: center;

  height: $action_button_height;
  min-width: 4 * $action_button_height;
  padding: 5px 10px;

  &.button.button--no-radius:hover:not([disabled]),
  &:hover:not([disabled]),
  &:active:not([disabled]) {
    color: $color_black;
    border: 1px solid $color_black;
    background-color: transparent;
  }
}