@import '../../../../scss/default/utils/variables';

$transferPlanVesselPictureWidthPx: 60px;
$itemHeightInPx: 120px;

.transfer-plan-list {
  max-height: 3 * $itemHeightInPx;
  overflow: auto;

  &--empty {
    margin-top: 20px;
    text-align: center;
    width: 300px;
  }
}

.transfer-plan-list__item {
  cursor: pointer;

  display: flex;
  flex-direction: column;

  padding: 15px 30px;

  &--selected {
    background-color: $color_light_grey_lightest;
  }
}

.transfer-plan-list__item__vessel-picture .svg-inline--fa {
   width: 100%;
 }

.transfer-plan-list__item__vessel-picture {
  width: $transferPlanVesselPictureWidthPx;
  margin: 0;
}

.transfer-plan-list__item__header {
  display: flex;
  align-items: center;
}

.transfer-plan-list__item__header .output-wrapper--name- .output-wrapper__output {
  padding-left: 0;
}

.transfer-plan-list__item__content {
  display: grid;
  grid-template-columns: $transferPlanVesselPictureWidthPx 1fr;
  column-gap: 20px;
}

.transfer-plan-list__item__transfer-plan-details {
  display: grid;
  grid-template-columns: 200px 150px 150px 1fr;
  column-gap: 10px;
}
