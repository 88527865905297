@import "src/javascript/scss/default/base";

html,
body,
#root,
.App {
  height: calc(100vh - #{$header_height});
  width: 100%;
  font-family: $font_primary;
  background-color: $color_background;
}

// This should prevent the header from having a scrollbar
.App {
  overflow: auto;
}