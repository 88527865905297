@import '../../../scss/default/utils/variables';

.user-menu__icon-wrapper {
  display: flex;
  justify-content: center;
  width: 60px;
  text-align: center;
  .icon{
    width: fit-content;
  }
}

.user-menu__overlay {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.user-menu__content {
  z-index: 2;
  position: fixed;
  top: 60px;
  right: 10px;
  background-color: $color_blackish_dark;
  border: 1px solid $color_blackish_dark;
  box-shadow: -2px 0 3px 0 $color_blackish,2px 2px 5px 0 $color_blackish;
}

.user-menu__content-group {
  display: flex;
  align-items: flex-start;

  margin: 0;
  padding: 5px 10px;

  width: 100%;
  font-size: 15px;
}

.user-menu__content-group:first-child {
  padding-top: 10px;
}

.user-menu__content-group:not(:last-child) {
  border-bottom: 1px solid $color_grey;
}

.user-menu__content-group .icon {
  margin-left: 5px;
  margin-right: 5px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.user-menu__user,
.user-menu__logout,
.user-menu__version {
  padding-left: 10px;
  padding-right: 10px;
}

.user-menu__user {
  font-weight: 700;
  padding: 0 5px 10px;
}

.user-menu__logout {
  text-decoration: underline;
  text-transform: capitalize;

  &:hover {
    cursor: pointer;
  }
}

.user-menu__version {
  font-size: 12px;
}