.switch-transfer-plan__icon-wrapper {
  display: flex;
  width: 60px;

  justify-content: center;
  text-align: center;

  .icon {
    width: fit-content;
  }
}