@import "src/javascript/scss/default/mixins";

.person-card-detail__header{
  text-align: center;
  padding: 10px 0;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  justify-content: space-between;
  cursor: pointer;

  .icon{
    margin-top: auto;
    margin-bottom: auto;
    svg{
      font-size: 20px;
    }
  }
  @include boarding-state();
}

.person-card-detail__header-title{
  grid-column-start: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.person-card-detail__header .icon{
  grid-column-start: 3;
  margin-left: auto;
  margin-right: 20px;
}
