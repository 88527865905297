@import "src/javascript/scss/default/utils/variables";
@import "src/javascript/scss/default/mixins";

.person-icon-bar{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto 0 10px 0;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;

  .icon:not(:last-child){
    padding-right: 5px;
  }
}

.person-icon-bar .icon{
  margin: 0;
  width: 16px;
}