@import "src/javascript/scss/default/utils/variables";

$card_margin: 4px;
$scroll-bar_width: 15px;

.person-placeholder-card{
  width: ($page_width - $scroll-bar_width - (14* $card_margin)) / 6;
  position: relative;
  border: 1px solid $black;
  cursor: pointer;
  margin: 12px;
  background-color: $color_light_grey;
  height: 172px;
}

.person-placeholder-card div img{
  top: 10%;
}

.person-placeholder-card__title{
  position: absolute;
  top: 60%;
  text-align: center;
  width: 100%;
}