@import '../../../../scss/default/mixins';

.person-detail-actions {
  padding: 0 10px 10px;

  display: flex;
  gap: 4px;
  justify-content: center;
  align-content: center;
}

.person-detail-actions__daughtercraft-actions__button,
.person-detail-actions__action {
  @include action-button();
}

.person-detail-actions__daughtercraft-actions__button__overlay .ant-popover-inner-content {
  padding: 4px;

  display: flex;
  flex-direction: column;
  gap: 4px;
}