@import "src/javascript/scss/default/utils/variables";
@import "src/javascript/scss/default/mixins";

$width: 130px;
$image-width: $width - ( ( $width / 10 ) * 3) + 10;


.person-card-detail-type-picture-company{
  background-color: $color_light_grey_lightest;
  display: grid;
  grid-gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.person-card-detail-type-picture-company__person-type{
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color_white;
  .icon{
    margin-right: 10px;
  }
  @include person-type();
}

.person-card-detail-type-picture-company__picture-and-tracking{
  display: flex;
  justify-content: center;
  align-items: center;
}

.person-card-detail-type-picture-company__picture{
  width: $image-width;
  height: $image-width;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  object-fit: cover;
  animation: fadeIn 1s;
}
.person-card-detail-type-picture-company__person-type,
.person-card-detail-type-picture-company__company{
  width: 200px;
  padding: 7px;
  border-radius: 60px;
  text-align: center;
}

.person-card-detail-type-picture-company__company{
  background-color: $color_light_grey;
  color: $black;
}