@import "src/javascript/scss/default/utils/variables";

.person-card-detail-body-location{
  display: flex;
}

.person-card-detail-body-location__time-ago{
  color: $color_grey;
  margin-right: 3px;
  margin-left: auto;
}