@import "src/javascript/scss/default/utils/variables";

.people-whereabouts-group__title{
    color: $color_black;
    margin: 4px 12px;
}

.people-whereabouts-group__content {
    display: flex;
    flex-wrap: wrap;
}

.people-whereabouts-group__content--hidden{
    display: none;
}

.people-whereabouts-group__icon--visible svg{
    color: $color_grey;
    transform: rotateX(180deg);
}

.people-whereabouts-group__icon--hidden svg{
    color: $color_grey;
}

.people-whereabouts-group__icon{
    margin: auto 0;
    position: relative;
    margin-right: 8px;
}

.people-whereabouts-group__content-container{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 2px solid $color_light_grey;
    margin: 0 12px;
}

.people-whereabouts-group__content-container-unplanned {
    .icon svg {
        color: $color_blackish_dark;
    }
}

.people-whereabouts-group__title-container{
    display: flex;
    justify-content: space-between;
}

.people-whereabouts-group__title-container .icon{
    margin: auto;
    
    svg{
        color: $color_grey;
    }
}

.people-whereabouts-group__title-container-unplanned {
    background-color: $color_crew;
    padding : 0 8px;
    border: 1px solid $color_blackish_dark;

    .icon svg {
        color: $color_blackish_dark;
    }
}

.people-whereabouts-group__content-container--connectionLost {
    .people-whereabouts-group__title,
    .icon--gateway svg {
        color: $color_connection_lost;
    }
}