@import "src/javascript/scss/default/utils/variables";

.person-card-footer{
  height: 30px;
  display: flex;
  align-items: center;
  color: $color_light_grey;
  background-color: $white;
  border-top: 1px solid $black;
  padding: 5px;
}

.person-card-footer__all-locations{
  font-size: 12px;
  width: 88%;
  margin-left: 5px;
  > :nth-child(2){
    border-top: 1px solid $color_light_grey;
  }
}

.person-card-footer__location--current,
.person-card-footer__location--previous{
  display: flex;
}

.person-card-footer__location--current{
  padding-bottom: 2px;
}

.person-card-footer__location--previous{
  padding-top: 2px;
}