@import "src/javascript/scss/default/utils/variables";

.person-card-header{
  height: 55px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 10px;
  color: $color_grey_dark;
}

.person-card-header--not-tracked{
  background-color: $color_blackish;
}

.person-card-header--tracked{
  background-color: $color_light_grey;
}

.person-card-header--internal:before,
.person-card-header--external:before{
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  border-width: 0 0 16px 16px;
  border-style: solid;
  box-shadow: 1px 1px 0 0 rgba($color_black, 0.75);
}

.person-card-header--internal:before{
  border-color: $color_internal $color_background;
}
.person-card-header--external:before{
  border-color: $color_external $color_background;
}

.person-card-header-overlay--internal:before{
  border-color: $color_internal $color_boundedness_background_overlay;
  border-width: 0 0 25px 25px;
}
.person-card-header-overlay--external:before{
  border-color: $color_external $color_boundedness_background_overlay;
  border-width: 0 0 25px 25px;
}
