@import "src/javascript/scss/default/utils/variables";

.person-card-footer__location-time-ago{
  color: $color_grey;
  text-align: right;
  max-width: 120px;
  min-width: fit-content;
}

.person-card-footer__location-name{
  color: $color_blackish_dark;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.person-card-footer__location--last-seen{
  color: $color_grey;
}