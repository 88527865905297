@import "src/javascript/scss/default/utils/variables";

.locations-card {
    display: flex;
    height: 50px;
}

.locations-card--planned-boarded {
    color: $color_light_grey_lightest;
    background-color: $color_planned_boarded;
    display: flex;
    min-height: 50px;
    width: 100%;
}

.locations-card--planned-not-boarded {
    color: $color_blackish_dark;
    background-color: $color_planned_not_boarded;
    display: flex;
    min-height: 50px;
    width: 100%;
}

.locations-card--unplanned-boarded {
    color: $color_light_grey_lightest;
    background-color: $color_unplanned_boarded;
    display: flex;
    min-height: 50px;
    width: 100%;
}

.locations-card--unplanned-not-boarded {
    color: $color_blackish_dark;
    background-color: $color_unplanned_not_boarded;
    display: flex;
    min-height: 50px;
    width: 100%;
}

.locations-card__status {
    width: 8px;
    min-height: 50px;
    margin-right: 2px;
}

.locations-card__status--crew {
    background-color: $color_crew;
}
.locations-card__status--worker {
    background-color: $color_worker;
}
.locations-card__status--visitor {
    background-color: $color_visitor;
}

.locations-card__image {
    width: 50px;
    min-height: 100%;
    margin-right: 8px;
}

.locations-card__details {
    margin: auto 0;
    width: 100%;
}

.locations-card__details-nok {
    font-size: 0.8em;
    min-width: fit-content;
}

.locations-card__details-person {
    font-weight: bold;
}

.locations-card__nok-container {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.locations-card__nok-container .person-icon-bar {
    width: unset;
    margin: unset;
    padding-left: unset;
    padding-right: unset;
}