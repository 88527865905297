@import "src/javascript/scss/default/utils/variables";
@import "src/javascript/scss/default/mixins";

.person-card-body{
  height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 45px;
  text-align: center;
  @include boarding-state();
}

.person-card-body__name{
  font-size: 15px;
  font-weight: bold;
  padding: 0 5px;
  overflow: hidden;
  word-break: break-word;
}

.person-card-body__nok{
  font-size: 12px;
  padding-top: 3px;
}